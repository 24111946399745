<template>

  <div :class="'btn sort ' + type + (showed ? ' showed ' : '')"  @click="toggle" v-click-outside="hide">
  
    <div class="ched">
      {{ name }}
      <i class="icon-caret-down"></i>
    </div>
    
    <div class="opts" :style="'max-height: ' + maxHeight + 'px'">
      <template
        v-for="option in options"
        >
        <div 
          @click="click(option)"
          class="opt"
          >
          {{ option.name }}
        </div>        
      </template>
    </div>
 

  </div>
  
</template>

<script setup>
import { progressProps } from 'naive-ui';


const router = useRouter();

const emits = defineEmits([
  'click', 'choose'
]);

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  ico: {
    type: String,
    required: false,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
  maxHeight: {
    type: Number,
    required: false,
    default: 300,
  },
});



const showed = ref(false);

const hide = () => {
  if(showed.value) 
    toggle();
};

const toggle = () => {
  showed.value = !showed.value;
};


// const emitsList = computed(() => {
//   return progressProps.options
// });

const click = (option) => {
 

  hide();
  if(option.event)
    emits(option.event, option)
  else if(option.link)
    router.push({
      path: option.link
    })
};
 
 
</script>

<style scoped lang="scss">


.sort{
  position: relative;
  display: flex;
  align-content: center;
}
.sort.right{
  justify-content: flex-end;
}
.sort .hnt{

}
.sort .ched{  
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
}
.sort .opts{
  display: none;
  position: absolute;
  right: 0;
  top: 35px;
  background-color: var(--un-background-color-gray);
  z-index: 100;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 140px;
  color: var(--gd-text-color);
  overflow-y: auto;

}
.sort.wide .opts{
  width: 100%;
}
.sort.showed .opts{
  display: initial;
}

.sort .opts .opt{
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.sort .opts .opt:hover{
  background-color: var(--un-background-color-gray-light);
} 
</style>
